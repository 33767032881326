/**
로그인 화면
 */
.container {
    flex-wrap: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.wrap {
    width: 50vh;
    height: 400px;
}

.area {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: stretch;
    justify-content: center;
    padding: 30px !important;
}

.box {
    display: flex;
    align-items: center;
    justify-content: center;flex-direction: column;
    width: 100%;
}

.top, .middle, .bottom {
    width: 100%;
}

.middle {
    display: flex;
    justify-content: space-between;
}
.bottom {
    display: flex;
    align-items: flex-end;
}
.brand {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: fit-content;
}

.brand > svg {
    margin: 10px 0 0 10px;
}

.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.25rem 0;
}